//物件進入螢幕事件
function intoView(a) {
    var _ths = this,
        _def = {
            sc: document,
            el: false,
            start: 0,
            end: 0,
            callback: function () {},
        },
        _opt, _inview = false,
        _setAction = function () {
            _intoView(_opt.el, _opt.start, _opt.end);
            if (_inview) {
                _opt.callback(_opt.el);
            }
        },
        _intoView = function (a, b, c) {
            var wh = window.innerHeight;
            let wt = $(window).scrollTop();

            a.each(function (i, v) {
                v = $(v);
                if (!v.inView) {
                    var tar = v.outerHeight(),
                        pos = v.offset(),
                        star = 0,
                        end = 0,
                        res = false;

                    if (pos) {
                        if (pos.top <= wt + wh - b) {
                            res = true;
                        }
                    }

                    if (!_inview && res) {
                        _inview = true;
                    }

                    a[i].inView = res;
                }
            });
        },
        _init = function (a) {
            _opt = $.extend(true, {}, _def, a);
            if (!_opt.el) {
                return false;
            }

            $(window).on('load', _setAction);
            $(_opt.sc).on('ready', _setAction);
            $(_opt.sc).on('scroll', _setAction);
        };

    _ths.setAction = _setAction;
    _init(a);
}


var a = new intoView({
    // sc: 'body',
    el: $('.viewDom'),
    start: 150,
    callback: function (b) {
        var ii = 0;

        b.each(function (i) {
            var _ths = $(this);
            console.log(this.inView);
            if (this.inView && !_ths.hasClass('animated')) {

                _ths
                    .addClass('animated')
                    .css('transition-delay', ii * 0.1 + 's');

                setTimeout(function () {
                    _ths
                        .addClass('animationItem')
                        .css('transition-delay', '');
                }, 100);

                ii++;
            }
        });
    }
});

a.setAction();