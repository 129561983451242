  // 進場動畫
  // var percent=0;
  // var timer=setInterval(function(){
  //   $(".bar").css("width",percent+"%")
  //   percent+=1
  //   if(percent>100){
  //     clearInterval(timer)
  //     $(".pageloading").addClass("compelet")
  //   }
  // },30);

  // $('.scrollLink').bind('click', function(e) {
  //     e.preventDefault();
  //     var target = $(this).attr("href");
  //     $('html, body').stop().animate({ scrollTop: $(target).offset().top}, 1000, function() {
  //       location.hash = target + 300;
  //     });
      
  //     return false;
  // });

function resize() {
  var winHeight = $(window).height();
  $(".modal-autoheight .modal-body").css({
    width: "auto",
    height: (winHeight - 200) + "px"
  });
}

$(function() {

  // 字數限制
  var len = 42; //
  $(".JQellipsis").each(function(i){
      if($(this).text().length>len){
          $(this).attr("title",$(this).text());
          var text=$(this).text().substring(0,len-1)+"...";
          $(this).text(text);
      }
  });
  
  /* Cookies */
  $(".close-cookies").on('click',function(e) {
      e.preventDefault();
      $(".cookies").addClass("close-cookies");
  });

  if ($(".modal-autoheight").length > 0) {
    $(".modal").on("show.bs.modal", resize);
    $(window).on("resize", resize);
  }

  $('.modal').on('show.bs.modal', function(){
    var margin_vertical = parseInt( $(this).find('.modal-dialog').css('margin-top') ) + parseInt( $(this).find('.modal-dialog').css('margin-bottom') ) || 0;
    var height_header   = parseInt( $(this).find('.modal-header').css('height') ) || 0;
    var height_footer   = parseInt( $(this).find('.modal-footer').css('height') ) || 0;
    var height_body     = ( window.innerHeight - height_header - height_footer - margin_vertical - 10 ) + 'px';
    $(this).find('.modal-body').css('max-height', height_body).css('overflow', 'auto');

    $('.cookies').css('z-index','1');
  });

  $('.btnScroll').on('click',function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var $target = $(this.hash);
      $target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
      if ($target.length) {
        var targetOffset = $target.offset().top - 75;
        $('html,body').animate({ scrollTop: targetOffset }, 1000);
        return false;
      }
    }
  });

  // haederBG
  $(window).on('scroll', function() {
    if ($(window).scrollTop() > 100) {
        $('.nav-header').addClass("active");
    }
    else {
        $('.nav-header').removeClass("active");
    }
  });

  // header
  $('.navbar-fostrapClick.link').on('click',function(){
    $('.nav-fostrap').toggleClass('visible');
    $('.nav-overlay').toggleClass('show');
  });
  
  $('.navbar-fostrapClick.member').on('click',function(){
    $('.nav-right').toggleClass('visible');
    // $('.nav-overlay').toggleClass('show');
  });

  $('.mobile-closeBtn').on('click',function(){
    $('.nav-fostrap').removeClass('visible');
    $('.nav-overlay').removeClass('show');
  })

  // Mobile arrow-down
  $('.mobile-menuBtn').on('click',function(){
    var serToggle =  $(this).closest('li').find('.dropdown');
    $(".dropdown").not(serToggle).removeClass('active');
    $('.mobile-menuBtn').not($(this)).removeClass('active');

    if($(this).hasClass('active')){
      $(this).removeClass('active');
      serToggle.removeClass('active');
    }else{
      $(this).addClass('active');
      serToggle.addClass('active');
    }
  });

  $('.sideMobilebtn').on('click',function(){
    $('.sidemenu_ul').toggleClass('active')
  })
  
  // top
  $(".float-link .link-btn.top").on('click',function () {
    $("html,body").animate({ "scrollTop": "0" })
  })
  
  $(window).on("load resize scroll", function (e) {
    var scroll = $(this).scrollTop();
    var wdh = $(window).height();
    if (scroll > 50) {
        $(".float-link").addClass("active");
    } else {
        $(".float-link").removeClass("active");
    }
  });

  var parallaxSliderOptions = {
    speed: 1000,
    effect: 'coverflow',
    parallax: true,
    loop: true,
    autoplay: {
      delay: 3000,
      stopOnLastSlide: false,
      disableOnInteraction: true,
    },
    grabCursor: true,
    centeredSlides: true,
    coverflowEffect: {
      rotate: 35,
      depth: 10,
      stretch: 0,
      modifier: 1,
      slideShadows: true
    },

    on: {
      init: function() {
        let swiper = this;
        for (let i = 0; i < swiper.slides.length; i++) {
          $(swiper.slides[i])
            .find('.img-container')
            .attr({
              'data-swiper-parallax': 0.35 * swiper.width,
              'data-swiper-paralalx-opacity': 0.5
            });
        }
        let index = swiper.activeIndex;
        $(swiper.slides).removeClass('active');
        $(swiper.slides[index]).addClass('active');
      },
      transitionEnd: function() {
        let swiper = this;
        let index = swiper.activeIndex;
        $(swiper.slides).removeClass('active');
        $(swiper.slides[index]).addClass('active');
      },
      resize: function() {
        this.update();
      }
    },

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };
  var slider = new Swiper('.image-slider', parallaxSliderOptions);

  var logoswiper = new Swiper(".logoSwiper .swiper", {
    speed: 2000,
    autoplay: {
      delay: 3000,
      stopOnLastSlide: false,
      disableOnInteraction: true,
    },
    
    slidesPerGroup: 1,
    centerInsufficientSlides: true,
    navigation: {
      nextEl: ".logoSwiper .swiper-button-next",
      prevEl: ".logoSwiper .swiper-button-prev",
    },
    breakpoints: { 
      320: {  //当屏幕宽度大于等于320
        slidesPerView: 2,
        spaceBetween: 10,
      },
      768: {  //当屏幕宽度大于等于768 
        slidesPerView: 3,
        spaceBetween: 10,
      },
      1280: {  //当屏幕宽度大于等于1280
        slidesPerView: 7,
        spaceBetween: 10,
      }
    }
  });

});